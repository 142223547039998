import React, {useState} from 'react';
import PropTypes from 'prop-types';

// Fields
import TextField from './fields/TextField';
import TextArea from './fields/TextArea';
import ImageField from './fields/ImageField';

const TemplatePreview = ({ screenshot, screenshotModal }) => {

  const [currentTab, setCurrentTab] = useState('page');

  const defaultClasses = 'rounded-sm group relative min-w-0 flex-1 overflow-hidden bg-white py-2 px-4 text-sm font-medium text-center';
  const tabVisibleClasses = `border border-primary text-primary ${defaultClasses}`;
  const tabNotVisibleClasses = `text-gray-500 hover:text-gray-700 ${defaultClasses}`;

  return (
    <React.Fragment>
      <div className="bg-white overflow-hidden shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          {currentTab === 'page' && (
            <img src={screenshot} />
          )}
          {currentTab === 'modal' && (
            <img src={screenshotModal} />
          )}
        </div>
      </div>  
      {screenshotModal !== null && (
        <React.Fragment>
          <div className="relative z-0 flex-1 px-2 flex items-center justify-center mt-4">
            <nav className="relative z-0 rounded-lg shadow flex w-2/5 cursor-pointer" aria-label="Tabs">
              <span onClick={() => setCurrentTab('page')} className={currentTab === 'page' ? tabVisibleClasses : tabNotVisibleClasses}>
                <span>Page Content</span>
              </span>
              <span onClick={() => setCurrentTab('modal')} className={currentTab === 'modal' ? tabVisibleClasses : tabNotVisibleClasses}>
                <span>Modal Content</span>
              </span>
            </nav>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

TemplatePreview.propTypes = {
  screenshot: PropTypes.string,
  screenshotModal: PropTypes.string,
};

export default TemplatePreview;