import React from 'react';

function TagsInput(props){
  const [allTags, setAllTags] = React.useState(props.allTags);
	const [tags, setTags] = React.useState(props.currentTags);
  console.log('TAGS', tags);
	const toggleTags = tag => {
    if (tags.includes(tag)) {
      setTags([...tags.filter((e) => e !== tag)]);
    } else {
      setTags([...tags, tag]);
    }
	};
	const addTags = event => {
    event.stopPropagation();
    event.preventDefault();
		if (event.target.value !== "") {
      setAllTags([...allTags, event.target.value]);
			setTags([...tags, event.target.value]);
			event.target.value = "";
		}
	};
	return (
		<div className="tags-input">
			<div id="tags" className="">
				{allTags.map((tag, index) => (
          <span onClick={() => toggleTags(tag)} key={`${tag}-${index}`} className={`cursor-pointer inline-flex items-center gap-x-0.5 rounded-md  px-5 py-3 text-xs font-medium m-1 ${tags.includes(tag) ? 'bg-secondary text-white' : 'bg-secondaryLight text-gray-600'}`}>
            {tag}
            {tags.includes(tag) && (
              <span className="group relative -mr-1 h-5 w-5 rounded-sm">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-white">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
                <span className="absolute -inset-1" />
              </span>
            )}
          </span>          
				))}
			</div>
      <div className="relative mt-2 flex items-center ml-1">
        <input
          onKeyUp={event => event.key === "Enter" ? addTags(event) : null}
          type="text"
          placeholder="Type a new tag and press enter to add it..."
          className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <input name="tag_list" type="hidden" value={tags.join()} />
      <div className="ml-1 mt-3 flex justify-end">
        <button type="submit" href="/contents?walkthrough=1" className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
            Save Tags
        </button>
      </div>
		</div>
	);
}

export default TagsInput