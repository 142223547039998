import React from 'react';
import PropTypes from 'prop-types';

const TextField = ({ fieldLabel, fieldName, fieldValue, customClass = '' }) => {

  const classes = `${customClass} appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm`;
  
  return (
    <React.Fragment>
      <label htmlFor="name" className="block text-sm font-medium text-gray-700">
        {fieldLabel}
      </label>
      <div className="mt-1">
        <input className={classes} type="text" name={fieldName} id="campaign_name" defaultValue={fieldValue} />
      </div>
    </React.Fragment>
  );
};

TextField.propTypes = {
  customClass: PropTypes.string,
  fieldLabel: PropTypes.string,
  fieldName: PropTypes.string,
  fieldValue: PropTypes.string,
};

export default TextField;