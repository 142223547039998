import React from 'react';
import PropTypes from 'prop-types';

// Fields
import TextField from './fields/TextField';
import TextArea from './fields/TextArea';
import ImageField from './fields/ImageField';
import ColorField from './fields/ColorField';

const TemplateFields = ({ fields, values }) => {
  const parsedFields = JSON.parse(fields);
  const parsedValues = JSON.parse(values);

  const getVal = field => parsedValues ? parsedValues[field] : '';

  const buildField = (fieldSlice, i) => {
    const key = fieldSlice[0];
    const info = fieldSlice[1];

    const fieldLabel  = info.name;
    const fieldName   = `campaign[template_fields][${key}]`;
    const fieldValue  = getVal(key);
    
    switch (info.type) {
      case 'color':
        return (
          <div key={i} className="mb-3">
            <ColorField fieldLabel={fieldLabel} fieldName={fieldName} fieldValue={fieldValue} /> 
          </div>
        );
      case 'image':
        return (
          <div key={i} className="mb-3">
            <ImageField fieldLabel={fieldLabel} fieldName={fieldName} fieldValue={fieldValue} />
          </div>
        );
      case 'textarea':
        return (
          <div key={i} className="mb-3">
            <TextArea fieldLabel={fieldLabel} fieldName={fieldName} fieldValue={fieldValue} />
          </div>
        );
      default:
        return (
          <div key={i} className="mb-3">
            <TextField fieldLabel={fieldLabel} fieldName={fieldName} fieldValue={fieldValue} />
          </div>
        )      
    }
  }

  return (
    <div className="fd-template-fields">
      {Object.entries(parsedFields).map((field, i) => {
        return buildField(field, i);
      })}
      <div className="mt-5 sm:mt-6">
        <button type="submit" className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:text-sm">
          Save
        </button>
      </div>
    </div> 
  );
};

TemplateFields.propTypes = {
  fields: PropTypes.string,
  values: PropTypes.string,
};

export default TemplateFields;