import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { HexColorPicker, HexColorInput } from "react-colorful";

const ColorField = ({ fieldLabel, fieldName, fieldValue, customClass = '' }) => {
  const [color, setColor] = useState(fieldValue ? `#${fieldValue}` : '');
  const [focused, setFocused] = useState(false);

  const classes = `${customClass} focus:ring-primary focus:border-primary flex-1 block w-full rounded-none sm:text-sm border-gray-300 px-3 py-2 border border-gray-300`;

  const onFocus = () => setFocused(true)
  const onBlur = () => setFocused(false)

  const pickerClasses = `${focused ? '' : 'hidden'} absolute top-10 right-0 z-50`;
  
  return (
    <React.Fragment>
      <label htmlFor="name" className="block text-sm font-medium text-gray-700">
        {fieldLabel}
      </label>
      <div className="mt-1 flex rounded-md shadow-sm relative">
        <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">#</span>
        <HexColorInput color={color} onChange={setColor} className={classes} id="campaign_name" name={fieldName} onFocus={onFocus}/>
        {/* <div className={pickerClasses}>
          <HexColorPicker color={color} onChange={setColor} onBlur={onBlur} />
        </div> */}
        <span style={{ backgroundColor: color }} className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50"></span>
      </div>
    </React.Fragment>
  );
};

ColorField.propTypes = {
  customClass: PropTypes.string,
  fieldLabel: PropTypes.string,
  fieldName: PropTypes.string,
  fieldValue: PropTypes.string,
};

export default ColorField;