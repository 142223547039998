// General Scripts that Power the Website
import ClipboardJS from 'clipboard';

// Doc Ready & Turbolinks Load
if (window.$ !== undefined) {
  $(document).on('turbolinks:load', function () {

    // Avatar Dropdown
    const avatar = $('#user-menu');
    const flyout = $('#user-menu-flyout');
    avatar.on('click', (e) => {
      e.stopPropagation();
      flyout.removeClass('hidden');
    });

    // Mobile Menu
    const mobileMenu = $('#mobile-menu');
    const mobileMenuBtn = $('#toggle-mobile-menu');
    mobileMenuBtn.on('click', (e) => {
      e.stopPropagation();
      mobileMenu.toggleClass('hidden');
    });

    // New Campaign Modal
    const campaignModal = $('#new-campaign-modal');
    const campaignModalBtn = $('#new-campaign-modal-btn');
    const campaignModalCloseBtn = $('#new-campaign-modal-close-btn');
    campaignModalBtn.on('click', () => {
      campaignModal.removeClass('hidden');
    });
    campaignModalCloseBtn.on('click', () => {
      campaignModal.addClass('hidden');
    });

    // Manage Tag Modal
    const tagsModal = $('#manage-tags-modal');
    const tagsModalBtn = $('#manage-tags-modal-btn');
    const tagsModalCloseBtn = $('#manage-tags-modal-close-btn');
    tagsModalBtn.on('click', function (e) {
      e.preventDefault();
      tagsModal.removeClass('hidden');
    });
    tagsModalCloseBtn.on('click', () => {
      tagsModal.addClass('hidden');
    }); 
    
    // Block 
    $('#tag_submit_form').on('keypress', e => {
      if (e.keyCode == 13) return false;
    });

    // Campaign Embed Modal
    const campaignEmbedModal = $('#campaign-embed-modal');
    const campaignEmbedModalBtn = $('#campaign-embed-modal-btn');
    const campaignEmbedModalCloseBtn = $('#campaign-embed-modal-close-btn');
    campaignEmbedModalBtn.on('click', () => {
      campaignEmbedModal.removeClass('hidden');
    });
    campaignEmbedModalCloseBtn.on('click', () => {
      campaignEmbedModal.addClass('hidden');
    });  

    // Close any open things
    $('html').on('click', () => {
      flyout.addClass('hidden');
    });

    // Hide Notices
    const hideNotice = () => {
      const notice = $('#notice-notification');
      if (notice.length) {
        notice.remove();
      }
    }
    $('#hide-notice').on('click', () => hideNotice());
    setTimeout(hideNotice, 10000);

    // Comming Soon
    $('.coming-soon').on('click', () => {
      alert("This feature is coming soon! We'll send an announcement when it's ready!")
    })

    // Range Slider
    function genMsg (day) {
      let dayC = Number(day);
      if (dayC === 0) {
        return `This message will be sent when the contact signs up.`
      } else {
        return `This message will be sent <span>${dayC} ${ (dayC === 1) ? "day" : "days" }</span> after the contact signs up.`;
      }
    }  

    const updateTime = day => {
      const dayC = Number(day);
      const timeInput = $('.send-at-time');
      const timeNow   = $('#send_immediately');
      if (dayC === 0) {
        timeInput.hide();
        timeNow.show();
      } else {
        timeNow.hide();
        timeInput.show();
      }
    }

    const rangeSlider = function(){
      let slider = $('.range-slider'),
          range = $('.range-slider__range'),
          value = $('.range-slider__value');
        
      slider.each(function(){
    
        value.each(function(){
          let value = $(this).prev().attr('value');
          $(this).html(value);
          $('.day-zero-msg').html(genMsg(value));
          updateTime(value);
        });

        range.on('input', function(){
          $(this).next(value).html(this.value);
          $('.day-zero-msg').html(genMsg(this.value));
          updateTime(this.value);
        });
      });
    };
    rangeSlider();  

    // Summernote
    $("#summernote").summernote({
      height: 200,
      useProtocol: false,
      toolbar: [
        ['para', ['style']],
        ['para2', ['ol', 'ul']],
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['insert', ['link', 'hr', 'video']],
        ['mis', ['fullscreen']]
      ]    
    });

    // Clipboard Initiate
    if ($(".clipboard").length) {
      const clipboard = new ClipboardJS('.clipboard');
      clipboard.on('success', function(e) {
        const currentText = $(e.trigger).text();
        $(e.trigger).text('COPIED!');
        setTimeout(() => {
          $(e.trigger).text(currentText);
        }, 700);
      });
    }

    // Delete Method Simple
    $(".deletealert").on("click", function () {
      if (window.confirm("Are you sure you want to do this? There is no going back.")) {
        return true;
      }
      return false;
    })  

    // Contacts Table Clickable
    $("#contacts-table").on('click', 'tr', function (e) {
      const link = $(this).data('href');
      if (link) {
        window.location = link;
      }
    });

  });
}


// Rewardful Script
// 1. Check for params
// 2. If params, set cookie for 30 days.
const fdParams = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});
function fdSetCookie(cName, cValue, expDays) {
  let date = new Date();
  date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
}
if (fdParams.rewardful) {
  console.log('Rewardful UUID', fdParams.rewardful);
  fdSetCookie('rewardfulUUID', fdParams.rewardful, 30);
}